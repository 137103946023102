import { DKButton, DKIcons } from "deskera-ui-library";
import React from "react";
import DatePicker from "react-datepicker";
import CloseIcon from "src/assets/Close";

export default function ActionOnDateSelect({ selectedDate, label, onSelect, syncSubmit, onClose }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center border-radius-l"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="bg-white grid border px-4 pb-3 pt-4 border-radius-l"
        style={{ width: "400px" }}
      >
        {/* Title Section */}
        <div className="text-muted text-left mb-3">
          <span className="font-weight-bold">{`Sync From Date:`}</span>
        </div>
        <div className="text-muted text-center mb-3">
          <span className="font-weight-bold">{label}</span>
        </div>

        {/* Date Picker Section */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="bg-white deskera-datepicker d-flex justify-content-center align-items-center mb-4">
            <DatePicker selected={selectedDate} onChange={(date) => onSelect(date)} inline={true} />
          </div>
        </div>

        {/* Action Buttons Section */}
        <div
          className="d-row-flex d-flex justify-content-end"
          style={{
            gap: "0.5rem",
          }}
        >
          <DKButton
            className="bg-white px-4 text-black border border-black"
            onClick={onClose}
            title={
              <>
                <span className="svg-icon svg-baseline mr-2 svg-disabled">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </>
            }
          />
          <DKButton className="bg-success px-4 text-white" onClick={syncSubmit} title={"Sync"} />
        </div>
      </div>
    </div>
  );
}
