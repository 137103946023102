import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import {
  getBigCommerceInventoryTransactions,
  migrateBigcommerceDataForInventoryTransactions,
} from "src/api";
import ArrowRight from "src/assets/ArrowRight";
import EmptyTray from "src/assets/EmptyTray";
import ForwardRight from "src/assets/ForwardRight";
import UpArrowIcon from "src/assets/UpArrow";
import ActionOnDateSelect from "src/components/ActionOnDateSelect";
import Alert from "src/components/Alert";
import SearchBar from "src/components/Filters/SearchBar";
import SearchFilter from "src/components/Filters/SearchFilter";
import SyncStatus from "src/components/SyncStatus";
import { loadAccounts, loadConfig, loadInventoryTransactions } from "src/redux/actions/bigcommerce";
import { addToast } from "src/redux/actions/toasts";
import { dateFormat_ddmmyyyy, yearFirstDateFormat } from "src/utils/Constants";
import customSelectTheme from "src/utils/selectTheme";
import { getQueryParams, Utility } from "src/utils/Utility";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    minWidth: "13rem",
  }),
};

function InventoryTransactions() {
  const dispatch = useDispatch();

  // Selectors
  const inventoryTransactions = useSelector((state) => state?.bigcommerce?.inventoryTransactions);
  const connectedStores = useSelector((state) => state?.bigcommerce?.accounts?.data ?? []);
  const bigcommerceConfig = useSelector((state) => state.bigcommerce.config);
  const tenantInfo = useSelector((state) => state.auth.user);
  // States
  const [status, setStatus] = useState([]);
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [search, setSearch] = useState("");
  const data = useMemo(() => inventoryTransactions?.data?.content || [], [inventoryTransactions]);
  const fullData = useMemo(() => inventoryTransactions?.data, [inventoryTransactions]);
  const [showSyncFromDate, setShowSyncFromDate] = useState(false);
  const [deskeraInvSyncDates, setDeskeraInvSyncDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [apiStatuses, setApiStatuses] = useState({
    fetchDeskeraInventory: false,
  });
  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];

  const SOURCE_OPTIONS = [
    { value: "BIGCOMMERCE", label: "Big Commerce" },
    { value: "DESKERA", label: "Deskera" },
    { value: "BOTH", label: "Both" },
  ];

  const statusOptions = [
    { value: "SUCCESSFUL", label: "Successful" },
    { value: "FAILED", label: "Failed" },
    { value: "PENDING", label: "Pending" },
  ];

  const STORE_OPTIONS = useMemo(() => {
    return connectedStores.map((store) => ({
      value: store?.id,
      label: store?.name,
    }));
  }, [connectedStores]);

  // Columns
  const columns = useMemo(
    () => [
      { Header: "Movement Type", accessor: "movementType" },
      {
        Header: "Transaction Date",
        accessor: "stockTransDate",
        Cell: ({ value }) =>
          value ? Utility.convertToDate(value, tenantInfo?.dateFormat || "DD/MM/YYYY") : "",
      },
      { Header: "Store Name", accessor: "storeName" },
      { Header: "Warehouse", accessor: "warehouse" },
      { Header: "Product Id", accessor: "productId" },
      { Header: "Product Name", accessor: "productName" },
      { Header: "Transaction Type", accessor: "transactionType" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Transaction Number", accessor: "transactionNo" },
      { Header: "Contact Name", accessor: "contactName" },
      { Header: "Rate/Value", accessor: "value" },
      { Header: "Source", accessor: "source" },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ row }) => (
          <SyncStatus
            status={row.original.syncStatus}
            reason={row.original.reason}
            id={row.original.cartCustomerId}
          />
        ),
      },
      { Header: "Sync Remark", accessor: "syncRemark" },
    ],
    [tenantInfo?.dateFormat]
  );

  // Table Configurations
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    pageOptions,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: fullData?.totalPages,
      autoResetPage: false,
      manualSortBy: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Handlers
  const refreshData = () => {
    if (!triggered) {
      setLoading(true);
      setTriggered(true);
      const queryParams = getQueryParams(query);
      dispatch(loadInventoryTransactions(pageSize, pageIndex, status, sortBy, search, queryParams));
      setLoading(false);
      setTriggered(false);
    }
  };

  function getFinancialStartEndDate() {
    let bookBeginningStartDate = tenantInfo?.bookBeginningStartDate
      ? new Date(tenantInfo?.bookBeginningStartDate)
      : new Date();
    let startDate = new Date().setDate(new Date().getDate() - 7);
    startDate = startDate >= bookBeginningStartDate ? startDate : bookBeginningStartDate;
    const endDate = new Date();
    startDate = new Date(startDate);
    return { startDate, endDate };
  }

  function getDateRange(financialDates) {
    let endDate = "";
    let startDate = "";
    if (!financialDates?.startDate || !financialDates.endDate) {
      return { startDate, endDate };
    }
    var sd = financialDates.startDate;
    var startMonth = sd.getMonth() + 1;
    var ed = financialDates.endDate ? financialDates.endDate : financialDates.startDate;
    var endMonth = ed.getMonth() + 1;
    endDate =
      ed.getUTCFullYear() +
      "-" +
      String(endMonth).padStart(2, "0") +
      "-" +
      String(ed.getDate()).padStart(2, "0");
    startDate =
      sd.getUTCFullYear() +
      "-" +
      String(startMonth).padStart(2, "0") +
      "-" +
      String(sd.getDate()).padStart(2, "0");
    return { startDate, endDate };
  }

  const updateSelectedStatus = (option) => {
    const selected = option ? [option.value] : [];
    setStatus(selected);
  };

  const setDateRangeForFilter = (financialDates) => {
    if (Utility.isEmpty(financialDates)) {
      financialDates = getFinancialStartEndDate();
    }

    setDeskeraInvSyncDates({
      startDate: new Date(),
    });
  };

  const handleSync = async (syncFn) => {
    setTriggered(true);
    try {
      await syncFn();
      dispatch(addToast({ type: "success", title: "Success", message: "Sync job started" }));
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response?.data?.message || "Unexpected error occurred",
        })
      );
    } finally {
      refreshData();
      setTriggered(false);
    }
  };

  const onFilter = useCallback(
    Utility.debounce((query) => {
      const search = encodeURIComponent(query["search"] ?? "");
      setSearch(search);
      gotoPage(0);
    }, 250),
    []
  );

  function handleQueryUpdate(key, value) {
    if (Utility.isEmpty(value)) {
      setQuery((prev) => {
        delete prev[key];
        return { ...prev };
      });
    } else {
      setQuery((prev) => {
        return { ...prev, [key]: value };
      });
    }
  }

  // Effects
  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadInventoryTransactions());
    if (!connectedStores?.data?.length) {
      dispatch(loadAccounts());
    }
  }, []);

  useEffect(() => refreshData(), [status, pageSize, pageIndex, query, search, sortBy]);

  useEffect(() => {
    setDateRangeForFilter();
  }, [tenantInfo]);

  // JSX
  return (
    <div className="p-4">
      <h3 className="mb-3">Inventory Transactions</h3>
      {!bigcommerceConfig?.data?.["twoWaySync"] && (
        <Alert type="warning">
          Your synced inventory transactions will appear here. Complete{" "}
          <Link to="/app/bigcommerce" className="text-alert-warning">
            Setup
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <div className="d-flex flex-column mb-2 space-between">
        <div style={{ width: "auto", gap: "1rem" }}>
          <div
            className="d-flex flex-row"
            style={{
              gap: "0.5rem",
            }}
          >
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={() => handleSync(migrateBigcommerceDataForInventoryTransactions)}
              disabled={triggered}
            >
              {triggered && <span className="spinner-border spinner-border-sm"></span>}
              Sync Deskera To BigCommerce
            </button>

            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={() => {
                setShowSyncFromDate(true);
              }}
              disabled={triggered}
            >
              {apiStatuses.fetchDeskeraInventory && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Fetch Deskera Inventory
            </button>
            {showSyncFromDate && (
              <ActionOnDateSelect
                selectedDate={deskeraInvSyncDates.startDate}
                label="Select Date From Where You Want To Fetch Deskera Inventory"
                onSelect={(newDate) => {
                  const bbDate = tenantInfo?.bookBeginningStartDate;
                  const bookBeginningStartDate = moment(bbDate, yearFirstDateFormat).toDate();
                  const isMoreThanToday = newDate > new Date();
                  if (newDate && newDate < bookBeginningStartDate) {
                    dispatch(
                      addToast({
                        type: "danger",
                        title: "Failure",
                        message:
                          "Selected date should be greater than the Book Beginning Start Date.",
                      })
                    );
                    return;
                  }
                  if (isMoreThanToday) {
                    dispatch(
                      addToast({
                        type: "danger",
                        title: "Failure",
                        message: "Selected date should be before today.",
                      })
                    );
                    return;
                  }
                  setDeskeraInvSyncDates({
                    startDate: newDate,
                  });
                }}
                syncSubmit={(e) => {
                  setShowSyncFromDate(false);
                  dispatch(
                    addToast({
                      type: "success",
                      title: "Success",
                      message: "Sync job started",
                    })
                  );
                  setApiStatuses((prev) => {
                    return { ...prev, fetchDeskeraInventory: true };
                  });
                  const payload = {
                    startDate: moment(deskeraInvSyncDates.startDate).format(dateFormat_ddmmyyyy),
                  };
                  getBigCommerceInventoryTransactions(payload.startDate)
                    .then((res) => {
                      dispatch(
                        addToast({
                          type: "success",
                          title: "Success",
                          message: res,
                        })
                      );
                    })
                    .catch((err) => {
                      dispatch(
                        addToast({
                          type: "danger",
                          title: "Failure",
                          message: err?.response?.data?.message || "Unexpected error occurred",
                        })
                      );
                    })
                    .finally(() => {
                      setApiStatuses((prev) => {
                        return { ...prev, fetchDeskeraInventory: false };
                      });
                      refreshData();
                    });
                }}
                onClose={(e) => {
                  setShowSyncFromDate(false);
                }}
              />
            )}
          </div>
          <div style={{ verticalAlign: "center", marginTop: "8px" }}></div>
          <div style={{ marginLeft: "2px" }}>
            <form className="form-inline">
              <SearchFilter
                style={{ display: "flex", justifyContent: "end", width: "15rem" }}
                onFilterMap={onFilter}
              >
                <SearchBar
                  placeholder={`Search Inventories`}
                  inputStyle={{ width: "12rem" }}
                  style={{ minWidth: "15rem", margin: "2px" }}
                  queryKey={"search"}
                />
              </SearchFilter>
              <Select
                placeholder="Filter By Sync Status"
                className="mr-2 ml-2"
                styles={customStyles}
                options={statusOptions}
                menuPlacement="auto"
                onChange={(e) => {
                  updateSelectedStatus(e);
                  gotoPage(0);
                }}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                isMulti={false}
              />

              {connectedStores?.length > 1 && (
                <Select
                  className="ml-2"
                  placeholder="Filter By Store"
                  options={STORE_OPTIONS}
                  styles={customStyles}
                  onChange={(option) => handleQueryUpdate("storeHash", option?.value || null)}
                />
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="card-without-background">
        <table
          {...getTableProps()}
          style={{
            backgroundColor: "#fff",
            backgroundClip: "border-box",
            border: "1px solid rgba(0, 0, 0, 0.125)",
          }}
          className="table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <span className="fs-r text-align-left fw-m fs-m text-gray cursor-hand">
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={columns.length}>Loading...</td>
              </tr>
            ) : data.length ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter((option) => option.value === pageSize)}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {Math.max(pageOptions.length, 1)}
          </span>
          <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ArrowRight />
            </span>
          </button>
          <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default InventoryTransactions;
